var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        "show-overlay": _vm.open,
        title: _vm.title,
        close: _vm.close,
        "content-size": "medium",
      },
    },
    [
      _c(
        "div",
        { staticClass: "full-width pt-8 pt-md-0" },
        [
          _vm.open
            ? _c("module-address-book", {
                ref: "addressInput",
                staticClass: "mb-4",
                on: { setAddress: _vm.setAddress },
              })
            : _vm._e(),
          _c("mew-button", {
            attrs: {
              title: "Send ETH Block",
              "has-full-width": "",
              "btn-size": "xlarge",
              disabled: _vm.disableSend,
              loading: _vm.isSending,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.sendBlock()
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }